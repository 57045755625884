import { RouteRecordRaw } from 'vue-router'

export const dmRoutes: RouteRecordRaw[] = [
  {
    path: '/dm/contracts',
    name: 'dmContractList',
    component: () => import('./ContractList.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/dm/contracts/:id',
    name: 'dmContract',
    component: () => import('./Contract.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  }
]
