import { getEventTrackingOptions, isProdEnv } from './config'
import { eventTrackingSwitch } from './decorator'
interface IProps {
  [key: string]: any
}
const eventOptions = getEventTrackingOptions()
const aplus_queue: any = {
  push(event: any) {
    window.aplus_queue.push(event)
  }
}

class EventTracking {
  enabled = true
  init() {
    this.setAppKey()
    this.setDomain()
    this.toggleLog(!isProdEnv())
    this.disableAutoRecord()
  }
  setReportStatus(enabled: boolean) {
    this.enabled = enabled
  }
  setAppKey() {
    this.setMetaInfo('appKey', eventOptions.appKey)
  }
  setDomain() {
    this.setMetaInfo('trackDomain', eventOptions.domain)
  }

  setUserId(userId: string) {
    this.setMetaInfo('_user_id', userId)
  }

  setUserInfo(phoneNumber: string, userRole?: string, props: IProps = {}) {
    const userProps: IProps = { phone_number: phoneNumber, ...props }
    if (userRole) {
      userProps.user_role = userRole
    }
    // 上报用户信息phone_number、user_role，会和_user_id相关联, setUserId后调用
    this.recordEvent('OTHER', '$$_user_profile', undefined, userProps)
  }

  setGlobalProperties(props: IProps) {
    this.setMetaInfo('globalproperty', props)
  }

  // DAF, E_CONTRACTING, CREDIT_LIMIT设置全局属性
  setCommonGlobalProperties(trackingData: ITrackingData) {
    const {
      id_card_number,
      phone_number,
      me_id, // meid
      belong_business, // 所属业务
      apply_pattern, // 申请模式
      apply_type, // 客户类型
      contract_type, // 合同类型
      operation_type, // 操作方
      business_type, // 业务类型
      user_role, // 用户角色
      application_id //申请ID
    } = trackingData
    this.setUserId(id_card_number)
    this.setGlobalProperties({
      phone_number,
      me_id,
      belong_business,
      apply_pattern,
      apply_type,
      contract_type,
      operation_type,
      business_type,
      user_role,
      application_id
    })
    this.setUserInfo(phone_number, user_role)
  }

  disableAutoRecord() {
    // 关闭自动上报
    this.setMetaInfo('aplus-disable-apv', true)
    // 关闭全埋点，即操作时根据元素属性上报数据
    this.setMetaInfo('aplus-autotrack-enabled', false)
  }
  toggleLog(enable = true) {
    this.setMetaInfo('DEBUG', enable)
  }
  toggleRecord(enable = true) {
    // 在初始化完成前禁止上报
    this.setMetaInfo('_hold', enable ? 'START' : 'BLOCK')
  }
  @eventTrackingSwitch
  browserPage(pageName: string, props: IProps = {}) {
    if (!this.enabled) {
      return
    }
    aplus_queue.push({
      action: 'aplus.sendPV',
      arguments: [
        {
          is_auto: false
        },
        {
          page_name: pageName,
          ...props
        }
      ]
    })
  }
  expEvent(eventCode: string, pageCode = '', props: IProps = {}) {
    // 记录曝光事件
    this.recordEvent('EXP', eventCode, pageCode, props)
  }
  clickEvent(eventCode: string, pageCode = '', props: IProps = {}) {
    // 记录点击事件
    this.recordEvent('CLK', eventCode, pageCode, props)
  }
  otherEvent(eventCode: string, pageCode = '', props: IProps = {}) {
    // 记录自定义事件
    this.recordEvent('OTHER', eventCode, pageCode, props)
  }
  @eventTrackingSwitch
  setMetaInfo(key: string, value: any) {
    if (!this.enabled) {
      return
    }
    aplus_queue.push({
      action: 'aplus.setMetaInfo',
      arguments: [key, value]
    })
  }
  @eventTrackingSwitch
  recordEvent(type: string, eventCode: string, pageCode = '', props: IProps) {
    if (!this.enabled) {
      return
    }
    const pageNameProps = pageCode ? { page_name: pageCode } : {}
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [
        eventCode,
        type,
        {
          ...props,
          ...pageNameProps
        }
      ]
    })
  }
}

export const eventTracking = new EventTracking()
