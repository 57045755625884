import { axiosservice } from 'dmt-shared-libs'
import type { components } from '@/models/service-models'
import { httpClient } from '@/utils/http'

export function getTrackingValues(contractSignatureId: string, customerId: string): Promise<{ [key: string]: string }> {
  return httpClient.get(`/api/tracking/values?contractSignatureId=${contractSignatureId}&customerId=${customerId}`)
}

export function getClientIDAndEkycID(
  contractSignatureId: string,
  customerId: string,
  companyId: string
): Promise<components['schemas']['AuthenticationParametersVo']> {
  return axiosservice.get(
    `/api/authentication-parameters?contractSignatureId=${contractSignatureId}&customerId=${customerId}&companyId=${companyId}`
  )
}

export const getAttachmentDocList = (
  contractSignatureId: string,
  company: string
): Promise<components['schemas']['DocumentGroupDto'][]> => {
  return axiosservice.get('/api/documents/groups', {
    params: {
      contractSignatureId,
      company
    }
  })
}

export const getAttachmentContent = (
  id: string,
  contractSignatureId: string,
  company: string,
  loading = true
): Promise<components['schemas']['ContractAttachmentFileResponse']> => {
  return axiosservice.get(`/api/documents/${id}/attachment-content`, {
    loading,
    params: {
      contractSignatureId,
      company
    }
  })
}

export const getSMSVerifyCode = (
  contractSignatureId: string,
  customerId: string
): Promise<components['schemas']['ApplySmsVerificationCodeResponseVo']> => {
  return axiosservice.post('/api/sms-verification-codes', {
    contractSignatureId,
    customerId
  })
}

export const signingContractBySMSCode = (
  contractSignatureId: string,
  customerId: string,
  code: string
): Promise<components['schemas']['ContractSignResponse']> => {
  return axiosservice.post(
    '/api/contracts/signing',
    {
      contractSignatureId,
      customerId,
      code
    },
    { ignore400: true } as any
  )
}

export const signingContractManual = (
  contractSignatureId: string,
  customerId: string,
  signaturePic: string
): Promise<components['schemas']['ContractSignResponse']> => {
  return axiosservice.post(
    '/api/contracts/signing',
    {
      contractSignatureId,
      customerId,
      signaturePic
    },
    { ignore400: true } as any
  )
}

export const cancelSigningContract = (
  contractSignatureId: string,
  customerId: string
): Promise<components['schemas']['ContractCancelResponseVo']> => {
  return axiosservice.get('/api/contracts/cancel-signing', { params: { contractSignatureId, customerId } })
}

export const getAgreementFiles = (
  upstreamApplication: string,
  companyId: string | number
): Promise<components['schemas']['AgreementsResponse']> => {
  return axiosservice.get('/api/agreements', {
    params: {
      type: 'charge',
      companyId,
      upstreamApplication
    }
  })
}

export const recordAgreementAction = (contractSignatureId: string, customerId: string, documentId: number | string) => {
  return axiosservice.post('/api/evidence-preservations/document-browse', {
    contractSignatureId,
    customerId,
    documentId
  })
}

export const recordReadImportantAction = (
  contractSignatureId: string,
  customerId: string,
  documents: Array<{ id: number | string; browseTime?: number }>
) => {
  return axiosservice.post('/api/evidence-preservations/documents-browse', {
    contractSignatureId,
    customerId,
    documents
  })
}

export const getContractContents = (
  contractSignatureId: string,
  customerId: string,
  loading = true
): Promise<components['schemas']['DocumentAttachmentContentDto'][]> => {
  return axiosservice.get('/api/documents/attachment-contents', {
    loading,
    params: {
      contractSignatureId,
      customerId
    }
  })
}
