export const errorCodeMapping: { [key: string]: string } = {
  CONTRACT_EXPIRED: '电子合同签署已过期',
  CONTRACT_IS_SIGNING: '合同正在被其他人签署',
  CUSTOMER_HAS_SIGNED_CONTRACT: '合同已经签署',
  WRONG_SMS_VERIFICATION_CODE: '短信验证码错误，请重新输入！',
  SMS_VERIFICATION_CODE_EXPIRED: '短信验证码过期，请重新输入！',
  SMS_CODE_IS_INVALID: '验证码已失效，请重新获取验证码！',
  EVENT_CERTIFICATE_FAILED: '事件证书签名错误！请您稍后再试。',
  SAVE_EVIDENCE_FAILED: '数据存储失败，请稍后再试。'
}
