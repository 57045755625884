import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "entry-flex-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MbHeader = _resolveComponent("MbHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MbFooter = _resolveComponent("MbFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MbHeader, {
      showheader: _ctx.showHeader,
      text: _ctx.text,
      showlogo: _ctx.showLogo
    }, null, 8, ["showheader", "text", "showlogo"]),
    _createVNode(_component_router_view),
    (_ctx.showFooter)
      ? (_openBlock(), _createBlock(_component_MbFooter, {
          key: 0,
          company: _ctx.company,
          "is-smart": _ctx.isSmart
        }, null, 8, ["company", "is-smart"]))
      : _createCommentVNode("", true)
  ]))
}