import { domains } from '@/config/common'

const appKeys: { [key: string]: string } = {
  dev: 'EUM-AAB-AXE',
  int: 'EUM-AAB-AXG',
  uat: 'EUM-AAB-AXH',
  production: 'EUM-AAB-AXF'
}
const getAppKey = () => {
  for (const key in domains) {
    if (location.hostname === domains[key]) {
      return appKeys[key]
    }
  }
  return appKeys.dev
}
const appKey = getAppKey()

const httpPotocol = 'http'
const adrumConfig = {
  appKey,
  adrumExtUrlHttp: `${httpPotocol}://cdn.appdynamics.com`,
  adrumExtUrlHttps: 'https://cdn.appdynamics.com',
  beaconUrlHttps: 'https://mbafc-appdynamics-eum.mercedes-benz-finance.com.cn',
  xd: { enable: false },
  spa: { spa2: true }
}

export const intAdrum = () => {
  window['adrum-start-time'] = new Date().getTime()
  window['adrum-config'] = adrumConfig
  const isHttps = window.location.protocol === 'https:'
  const protocol = isHttps ? 'https' : 'http'
  const url = `${protocol}://cdn.appdynamics.com/adrum/adrum-20.12.0.3360.js`
  const script = document.createElement('script')
  script.src = url
  script.type = 'text/javascript'
  script.async = true
  document.body.append(script)
}
