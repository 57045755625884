import { RouteRecordRaw } from 'vue-router'

export const creditLimitRoutes: Array<RouteRecordRaw> = [
  {
    path: '/credit-limit/contract',
    name: 'creditLimitContract',
    component: () => import('./Contract.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  }
]
