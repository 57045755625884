import { RouteRecordRaw } from 'vue-router'

export const dafRoutes: Array<RouteRecordRaw> = [
  {
    path: '/daf/contract',
    name: 'dafContract',
    component: () => import('./Contract.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  }
]
