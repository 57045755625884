<template>
  <div class="entry-flex-container">
    <MbHeader :showheader="showHeader" :text="text" :showlogo="showLogo"></MbHeader>
    <router-view />
    <MbFooter v-if="showFooter" :company="company" :is-smart="isSmart"></MbFooter>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import some from 'lodash-es/some'
import { MbHeader, MbFooter } from 'mbmt-ui'
import { getBusinessType, getSessionStorage } from './utils/storage'
import { CompanyType } from './utils/company'
import { BusinessType } from './config/common'

export default defineComponent({
  components: {
    MbHeader,
    MbFooter
  },
  setup() {
    const showHeader = ref(false)
    const showLogo = ref(false)
    const showFooter = ref(false)
    const text = ref('')
    const company = ref(0)
    const isSmart = ref(false)

    const route = useRoute()

    const getTitle = (companyId?: string) => {
      if (!companyId) return 'esign'
      return companyId == CompanyType.AFC ? '奔驰汽车金融' : '奔驰租赁'
    }

    const updateTitle = () => {
      const routeCompanyId = route.query.companyId as string
      const companyId = getSessionStorage('companyId')
      const title = getTitle(companyId || routeCompanyId)
      if (document.title !== title) {
        document.title = title
      }
    }

    watch(route, ({ meta }) => {
      const companyId = getSessionStorage('companyId')
      const leadSource = getSessionStorage('leadSource')
      company.value = Number(companyId)

      // CSS、CL、DM 不需要处理smart
      const businessType = getBusinessType()
      const skipSmart = some(
        [BusinessType.CREDIT_LIMIT, BusinessType.CSS, BusinessType.DM],
        item => item === businessType
      )
      isSmart.value = !skipSmart && leadSource ? leadSource.toLowerCase().indexOf('smart') !== -1 : false

      const { header, footer } = meta as any
      showHeader.value = !!header?.show
      text.value = header?.text || ''
      showLogo.value = !!header?.showLogo
      showFooter.value = !!footer

      updateTitle()
    })

    return {
      showHeader,
      showLogo,
      text,
      showFooter,
      company,
      isSmart
    }
  }
})
</script>

<style lang="scss">
#app {
  font-family: PingFang SC, Microsoft YaHei, Helvetica, Hiragino Sans GB, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

html,
body {
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.entry-flex-container {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
}
.logo-container {
  user-select: none;
  -webkit-user-select: none;
}
</style>
