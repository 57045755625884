import { eventTracking } from '../EventTracking'
import { ISignatureEvent, subscribeSignature } from '../eventbus'
import { BusinessType } from '@/config/common'
import { eventTrackingByBusiness } from '../decorator'

const pageCode = 'ea_apply_auth_page'

class DAFEventTracking {
  time = 0
  @eventTrackingByBusiness(BusinessType.DAF)
  setGlobalProperties(trackingData: ITrackingData) {
    eventTracking.setCommonGlobalProperties(trackingData)
    this.subscribe()
  }
  browserPage() {
    this.time = new Date().getTime()
    eventTracking.browserPage(pageCode)
  }

  clickAgree() {
    const duration = new Date().getTime() - this.time
    eventTracking.clickEvent('ea_apply_agree_clk', pageCode, { action_duration: duration / 1000 })
  }

  signature(event: ISignatureEvent) {
    eventTracking.otherEvent('ea_apply_confirm_result', pageCode, event)
  }

  @eventTrackingByBusiness(BusinessType.DAF)
  subscribe() {
    subscribeSignature(this.signature)
  }
}

export const dafEventTracking = new DAFEventTracking()
