import { eventTracking } from '../EventTracking'
import { ISignatureEvent, subscribeSignature } from '../eventbus'
import { eventTrackingByBusiness } from '../decorator'
import { BusinessType } from '@/config/common'

const pageCode = 'cl_limit_auth_page'
class CreditLimitEventTracking {
  time = 0

  @eventTrackingByBusiness(BusinessType.CREDIT_LIMIT)
  setGlobalProperties(trackingData: ITrackingData) {
    const {
      id_card_number,
      phone_number,
      me_id, // meid
      belong_business, // 所属业务
      apply_pattern, // 申请模式
      apply_type, // 客户类型
      contract_type, // 合同类型
      operation_type, // 操作方
      business_type, // 业务类型
      user_role, // 用户角色
      application_id, //申请ID
      identifier_id, //识别标志_ID
      identifier_car, //识别标志_车型
      identifier_dealer, //识别标志_经销商
      identifier_tags, //识别标志_其他
      identifier_campaign //识别标志_活动
    } = trackingData
    eventTracking.setUserId(id_card_number)
    eventTracking.setGlobalProperties({
      phone_number,
      me_id,
      belong_business,
      apply_pattern,
      apply_type,
      contract_type,
      operation_type,
      business_type,
      user_role,
      application_id,
      identifier_id,
      identifier_car,
      identifier_dealer,
      identifier_tags,
      identifier_campaign
    })
    eventTracking.setUserInfo(phone_number, user_role)
    this.subscribe()
  }

  browserPage() {
    this.time = new Date().getTime()
    eventTracking.browserPage(pageCode)
  }

  clickAgree() {
    const duration = new Date().getTime() - this.time
    eventTracking.clickEvent('cl_limit_agree_clk', pageCode, { action_duration: duration / 1000 })
  }

  signature(event: ISignatureEvent) {
    event.failed_reason = event.fail_reason // 参数名称不统一, CL里面叫failed_reason
    delete event.fail_reason
    eventTracking.otherEvent('cl_limit_submit_result', pageCode, event)
  }

  @eventTrackingByBusiness(BusinessType.CREDIT_LIMIT)
  subscribe() {
    subscribeSignature(this.signature)
  }
}

export const creditLimitEventTracking = new CreditLimitEventTracking()
