import { BusinessType } from '@/config/common'
import { getSessionStorage } from '@/utils/storage'
import { eventTrackingByBusiness } from '../decorator'
import {
  ISignatureEvent,
  subscribeSignature,
  subscribeSignatureSuccessPage,
  subscribeSignatureSuccessPageButton,
  subscribeSignatureFailedPage,
  subscribeContractFilesPageClickNav,
  subscribeBrowserSignature
} from '../eventbus'
import { eventTracking } from '../EventTracking'

// 非due day change埋点页
const contractListPageCode = 'sign_contract_page'
const signatureSuccessPageCode = 'sign_result_success_page'
const signatureFailedPageCode = 'sign_result_fail_page'

// due day change埋点
const dueDayChangeContractListPageCode = 'change_contract_list_page'
const dueDayChangeContractDetailPageCode = 'change_contract_detail_page'
const dueDayChangeSignaturePageCode = 'change_contract_sign_page'

class CSSEventTracking {
  dueChangeDayContractFileTime = 0
  get isDueDayChange() {
    return getSessionStorage('docType') === 'dueDayChange'
  }
  @eventTrackingByBusiness(BusinessType.CSS)
  setGlobalProperties(trackingData: ITrackingData) {
    const {
      phone_number,
      id_card_number,
      apply_pattern, // 申请模式
      apply_type, // 客户类型
      contract_number, // 合同号
      contract_type, // 合同类型
      operation_type, // 操作方
      user_level, // 用户类型
      business_type, // 业务类型
      user_role // 用户角色
    } = trackingData
    eventTracking.setUserId(id_card_number)
    eventTracking.setGlobalProperties({
      apply_pattern,
      apply_type,
      contract_number,
      contract_type,
      operation_type,
      user_level,
      business_type,
      user_role
    })
    // 用户属性 phone_number, user_role, registered_channel 微信公众号, id_card_number
    eventTracking.setUserInfo(phone_number, user_role, { registered_channel: '微信公众号', id_card_number })
    this.subscribe()
  }
  browserPage() {
    //签署电子合同页
    eventTracking.browserPage(contractListPageCode)
  }

  electronicSignButton(buttonName: string) {
    //签署电子合同页_控件点击 ==> 弹出框中的电子签名或提交按钮
    eventTracking.clickEvent('scp_button_clk', contractListPageCode, { button_name: buttonName })
  }

  viewContract() {
    // 签署电子合同页_阅读合同
    eventTracking.otherEvent('scp_read_contract', contractListPageCode)
  }

  confirmSignButton() {
    // 签署电子合同页_确认签署 ==> 列表页的签署按钮
    eventTracking.otherEvent('scp_sign_affirm', contractListPageCode)
  }

  signature(event: ISignatureEvent) {
    // 签署电子合同页_验证码签署 或 签署电子合同页_手写签名签署
    const isManual = event.isManual
    delete event.isManual
    eventTracking.otherEvent(
      isManual ? 'scp_handwritten_signature_sign' : 'scp_verification_code_sign',
      contractListPageCode,
      event
    )
  }

  browserSignatureSuccessPage() {
    // 签署成功页
    eventTracking.browserPage(signatureSuccessPageCode)
  }

  signatureResultButtonClick(buttonName?: string) {
    // 签署成功页_按钮点击
    eventTracking.clickEvent('srsp_button_clk', signatureSuccessPageCode, { button_name: buttonName })
  }

  browserSignatureFailedPage() {
    // 签署失败页
    eventTracking.browserPage(signatureFailedPageCode)
  }

  /** ========= due change day事件 begin ========= */

  browserDueChangeDayContractListPage() {
    // due change day合同列表页
    eventTracking.browserPage(dueDayChangeContractListPageCode)
  }

  beginReadContractButtonClick() {
    // due change day合同列表页点击开始阅读合同及文档按钮
    eventTracking.clickEvent('change_contract_list_read_clk', dueDayChangeContractListPageCode)
  }

  browserDueChangeDayContractFilesPage() {
    this.dueChangeDayContractFileTime = new Date().getTime()
    // due change day合同详情页
    eventTracking.browserPage(dueDayChangeContractDetailPageCode)
  }

  dueChangeDayContractFilesPageClickNav(contractName: string) {
    // CSS_合同详情页_导航点击
    eventTracking.clickEvent('change_contract_detail_nav_clk', dueDayChangeContractDetailPageCode, {
      button_name: contractName
    })
  }

  dueChangeDayAllReadedButtonClick() {
    const duration = new Date().getTime() - this.dueChangeDayContractFileTime
    eventTracking.clickEvent('change_contract_detail_agree_clk', dueDayChangeContractDetailPageCode, {
      action_duration: duration / 1000
    })
  }

  dueChangeDayElectronicSignButton() {
    eventTracking.clickEvent('change_contract_detail_sign_clk', dueDayChangeContractDetailPageCode)
  }

  browserDueChangeDaySignaturePage() {
    // due change day电子签署页
    eventTracking.browserPage(dueDayChangeSignaturePageCode)
  }

  dueChangeDaySignature(event: ISignatureEvent) {
    // 签署电子合同页_验证码签署 或 签署电子合同页_手写签名签署
    delete event.isManual
    eventTracking.otherEvent('change_contract_sign_result', dueDayChangeSignaturePageCode, event)
  }

  /** ========= due change day事件 end ========= */

  @eventTrackingByBusiness(BusinessType.CSS)
  subscribe() {
    if (this.isDueDayChange) {
      subscribeContractFilesPageClickNav(this.dueChangeDayContractFilesPageClickNav)
      subscribeBrowserSignature(this.browserDueChangeDaySignaturePage)
      subscribeSignature(this.dueChangeDaySignature)
    } else {
      subscribeSignature(this.signature)
      subscribeSignatureSuccessPage(this.browserSignatureSuccessPage)
      subscribeSignatureSuccessPageButton(this.signatureResultButtonClick)
      subscribeSignatureFailedPage(this.browserSignatureFailedPage)
    }
  }
}

export const cssEventTracking = new CSSEventTracking()
