import { BusinessType } from '@/config/common'

export const getSessionStorage = (key: string, defaultValue = '') => {
  return sessionStorage.getItem(key) || defaultValue
}

export const setBusinessType = (businessType: string) => {
  sessionStorage.setItem('theme', businessType)
}

export const getBusinessType = () => {
  return getSessionStorage('theme') as BusinessType
}
