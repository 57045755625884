import { createApp } from 'vue'
import { Dialog } from 'vant'
import App from './App.vue'
import router from './router'
import store from '@/store'
import './config/odicsetup'
import { patch } from './utils/axios'

import './assets/css/reset.scss'
import 'vant/lib/index.css'
import './assets/css/common.scss'
import { initAliSDK } from './event-tracking/qtsdk'
import { intAdrum } from './event-tracking/adrum'
import { initSentry } from './event-tracking/sentry'

patch()
initAliSDK()
intAdrum()
const app = createApp(App)
app.use(store)
app.use(router)
app.use(Dialog)

app.mount('#app')

initSentry(app, router)
