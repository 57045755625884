import { RouteRecordRaw } from 'vue-router'

export const eContractingRoutes: RouteRecordRaw[] = [
  {
    path: '/e-contracting/contracts',
    name: 'eContractingList',
    component: () => import('./ContractList.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/e-contracting/contract-files',
    name: 'eContractingFiles',
    component: () => import('./ContractFiles.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/e-contracting/important-term/:id',
    name: 'eContractingImportantTerm',
    component: () => import('./ImportantTerm.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  }
]
