import { ENABLE_EVENT_TRACKING } from './config'
import { eventTracking } from './EventTracking'

/**
 * 集成埋点sdk
 */
export const initAliSDK = () => {
  // 开关关闭的情况下就不加载埋点sdk了
  if (!ENABLE_EVENT_TRACKING) {
    return
  }
  if (!window.aplus_queue) {
    window.aplus_queue = []
  }
  const script = document.createElement('script')
  script.async = true
  script.id = 'beacon-aplus'
  script.src = 'https://o.alicdn.com/QTSDK/quicktracking-sdk/qt_web.umd.js'
  eventTracking.init()
  document.body.appendChild(script)
}
