import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import * as libs from 'dmt-shared-libs'
import { dafRoutes } from '@/views/daf/routes'
import { cssRoutes } from '@/views/css/routes'
import { eContractingRoutes } from '@/views/e-contracting/routes'
import { dmRoutes } from '@/views/dm/routes'
import { creditLimitRoutes } from '@/views/credit-limit/routes'
import { eventTrackingGuard } from '@/event-tracking/global-property-guard'

const signResultRoutes: Array<RouteRecordRaw> = [
  {
    path: '/signing-error',
    name: 'signingerror',
    component: () => import('../views/SigningError.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/signing-success',
    name: 'signingSuccess',
    component: () => import('../views/SigningSuccess.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  }
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/landing',
    name: 'landing',
    component: () => import('../views/landing/LandingView.vue')
  },
  {
    path: '/entry',
    name: 'entry',
    component: () => import('../views/entry/EsignEntry.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () => import('../views/InvalidPage.vue')
  },
  {
    path: '/contract-expired',
    name: 'contractExpired',
    component: () => import('../views/ContractExpired.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/signature',
    name: 'signature',
    component: () => import('../views/Signature.vue'),
    meta: { header: { show: true, text: '', showLogo: true } }
  },
  {
    path: '/pdf-preview',
    name: 'pdfPreview',
    component: () => import('../views/PdfPreview.vue'),
    meta: { header: { show: true, text: '', showLogo: true } }
  },
  ...dafRoutes,
  ...cssRoutes,
  ...eContractingRoutes,
  ...dmRoutes,
  ...creditLimitRoutes,
  ...signResultRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(eventTrackingGuard)

router.beforeEach((to, from, next) => {
  const code = sessionStorage.getItem('code')
  // 只有当code存在且目标路由query中未包含code时才进行添加
  if (code && (!to.query || !to.query.code) && to.name !== 'signingSuccess') {
    const newTo = {
      ...to,
      query: {
        ...to.query,
        code
      }
    }
    next(newTo)
  } else {
    next()
  }
})
export default router

export function getUrlArguments() {
  return libs.getUrlArguments(router.currentRoute.value)
}

export function getUrlArgument<key extends keyof libs.UrlParamsType>(name: key) {
  return libs.getUrlArgument(router.currentRoute.value, name)
}

export function getUrlArgumentsOfType() {
  return libs.getUrlArgumentsOfType(router.currentRoute.value)
}
