import { RouteRecordRaw } from 'vue-router'

export const cssRoutes: Array<RouteRecordRaw> = [
  {
    path: '/css/contracts',
    name: 'cssContractList',
    component: () => import('./ContractList.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/css/contracts/:id',
    name: 'cssContract',
    component: () => import('./Contract.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  },
  {
    path: '/css/contract-files',
    name: 'cssContractingFiles',
    component: () => import('./ContractFiles.vue'),
    meta: { header: { show: true, text: '', showLogo: true }, footer: true }
  }
]
