import { alertMsg, axiosservice } from 'dmt-shared-libs'
import isFunction from 'lodash-es/isFunction'
import get from 'lodash-es/get'
import { toastMsg } from 'dmt-shared-libs'
import { getSessionStorage, setBusinessType } from './storage'
import router from '../router'
import { includes } from 'lodash-es'
import { getSuccessHandler } from '@/services/contract'
import { errorCodeMapping } from '@/config/errors'

// 鉴权、获取用户是通过share libs拿的，它们是不需要contract id请求头的，加上会有问题，只能通过页面进行过虑了，不然就得重新封装一个axios请求才能区分esign与share libs的请求
const skipContractSignatureIdRoutes = ['landing', 'entry']
let shouldIgnoreContractSignatureIdHeader = false // 是否忽略contract id请求头
router.beforeEach(to => {
  const { name } = to
  shouldIgnoreContractSignatureIdHeader = includes(skipContractSignatureIdRoutes, name)
})

type IHandler = ((data?: any) => void) | string
const errorHandlersFor400: { [key: string]: IHandler } = {
  CONTRACT_EXPIRED: () => {
    router.push('/contract-expired')
  },
  CONTRACT_IS_SIGNING: () => {
    return alertMsg({
      message: '合同正在被其他人签署,请稍后再试!',
      className: 'authorized-alert'
    })
  },
  CUSTOMER_HAS_SIGNED_CONTRACT: (data: any) => {
    const link = get(data, 'error.message')
    const businessType = get(data, 'error.data')
    setBusinessType(businessType)
    const handler = getSuccessHandler(router, link || '')
    handler()
  }
}

export const handlerError = (code: string, ignore400 = false, data: any): boolean => {
  const handler = errorHandlersFor400[code]
  if (isFunction(handler)) {
    handler(data)
    return true
  } else if (handler) {
    toastMsg({ message: handler })
    return true
  } else if (!ignore400) {
    toastMsg('系统异常，请您稍后再试')
  }
  return false
}

const patchRequestInterceptor = () => {
  axiosservice.interceptors.request.use(config => {
    const contractSignatureId = getSessionStorage('contractSignatureId')
    const customerId = getSessionStorage('customerId')
    if (!shouldIgnoreContractSignatureIdHeader) {
      if (contractSignatureId) {
        config.headers!['X-CONTRACT-SIGNATURE-ID'] = contractSignatureId
      }
      if (customerId) {
        config.headers!['X-CUSTOMER-ID'] = customerId
      }
    }

    return config
  })
}

const patchResponseInterceptor = () => {
  axiosservice.interceptors.response.use(
    response => response,
    error => {
      const { data, status, config } = error
      if (status === 400) {
        const code = get(data, 'error.code')
        const handled = handlerError(code, get(config, 'ignore400'), data)
        error.isConsumed = handled
        error.ERROR_MESSAGE = errorCodeMapping[code] || '系统异常' // 给埋点使用的错误消息
      }
      if (status === 401) {
        const contractSignatureId = sessionStorage.getItem('contractSignatureId')
        const customerId = sessionStorage.getItem('customerId')
        const companyId = sessionStorage.getItem('companyId')
        location.href = `landing?contractSignatureId=${contractSignatureId}&customerId=${customerId}&companyId=${companyId}`
      }
      return Promise.reject(error)
    }
  )
}

export const patch = () => {
  patchRequestInterceptor()
  patchResponseInterceptor()
}
