import { domains } from '@/config/common'
import some from 'lodash-es/some'

export const isDevEnv = () => {
  return (
    window.location.port === '10088' ||
    some([domains.dev, domains.int, domains.uat], hostname => hostname === window.location.hostname)
  )
}

export const isINT = () => window.location.hostname === domains.int

export const isUAT = () => window.location.hostname === domains.uat

export const isProdEnv = () => window.location.hostname === domains.production

export const ENABLE_EVENT_TRACKING = true // 埋点开关

export const DEV_EVENT_TRACKING_OPTIONS = {
  appKey: 'pd2cusmqub485glbb1v4swd7',
  domain: 'https://quicktracking-test-api.mercedes-benz-finance.com.cn'
}

export const EVENT_TRACKING_OPTIONS = {
  appKey: 'pi5b4cfoioshr2xt2gbqa37t',
  domain: 'https://quicktracking-api.mercedes-benz-finance.com.cn'
}

export const getEventTrackingOptions = () => {
  return isProdEnv() ? EVENT_TRACKING_OPTIONS : DEV_EVENT_TRACKING_OPTIONS
}

export const SIGNATURE_EVENT_KEY = 'SIGNATURE_EVENT'
export const SIGNATURE_SUCCESS_PAGE = 'SIGNATURE_SUCCESS_PAGE'
export const SIGNATURE_SUCCESS_PAGE_BUTTON = 'SIGNATURE_SUCCESS_PAGE_BUTTON'
export const SIGNATURE_FAILED_PAGE = 'SIGNATURE_FAILED_PAGE'
export const SIGNATURE_PAGE = 'SIGNATURE_PAGE'
export const CONTRACT_FILES_PAGE_CLICK_NAV = 'CONTRACT_FILES_PAGE_CLICK_NAV'

// sentry常量
export const SENTRY_DAF_READING_DOCUMENTS = 'DAF document-DAF合同阅读时间'
export const SENTRY_DAF_SIGNATURE = 'DAF Signagure-DAF签署时间'
export const SENTRY_E_CONTRACTING_SIGNATURE = 'E-CONTRACTING Signagure-eContract签署时间'

export const SENTRY_ENTRY = 'SENTRY_ENTRY'
export const SENTRY_DAF_CLICK_AGREE = 'SENTRY_DAF_CLICK_AGREE'
export const SENTRY_DAF_AFTER_CLICK_AGREE = 'SENTRY_DAF_AFTER_CLICK_AGREE'
export const SENTRY_ENTRY_SIGNATURE = 'SENTRY_ENTRY_SIGNATURE'
export const SENTRY_SIGNATURE_END = 'SENTRY_SIGNATURE_END'
