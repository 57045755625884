import axios from 'axios'
import { getStorageSync } from 'dmt-shared-libs'

// 这个http client只是给不需要全局错误的场景使用，如获取tracking data ，其它场景请使用axiosservice
export const httpClient = axios.create({
  headers: {
    'Accept-Language': 'zh-CN',
    'Content-Type': 'application/json;charset=utf-8',
    'X-Enum-Serialize-Type': 'INT'
  },
  timeout: 20000
})

httpClient.interceptors.request.use(config => {
  const tk = getStorageSync('tk')
  const tk_type = getStorageSync('tk_type')
  const Authorization = `${tk_type} ${tk}`
  if (Authorization) {
    config.headers!['Authorization'] = Authorization
  }
  return config
})
