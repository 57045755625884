import { Router } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { BusinessType, domains } from '@/config/common'
import map from 'lodash-es/map'
import startsWith from 'lodash-es/startsWith'
import {
  SENTRY_DAF_AFTER_CLICK_AGREE,
  SENTRY_DAF_CLICK_AGREE,
  SENTRY_DAF_READING_DOCUMENTS,
  SENTRY_DAF_SIGNATURE,
  SENTRY_ENTRY,
  SENTRY_E_CONTRACTING_SIGNATURE,
  SIGNATURE_EVENT_KEY,
  SIGNATURE_PAGE
} from './config'
import { getBusinessType } from '@/utils/storage'
import { eventBus } from '@/utils/subscribe'

const servers = map(domains, domain => `https://${domain}`)
servers.push('localhost')
const getEnvironment = () => {
  for (const key in domains) {
    if (location.hostname === domains[key]) {
      return key
    }
  }
  return 'development'
}

export const initSentry = (app: any, router: Router) => {
  Sentry.init({
    app,
    dsn: 'https://40b80a4fc0a442128e6320737e03760c@fscnsentry.mercedes-benz-finance.com.cn/8',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: servers,
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        shouldCreateSpanForRequest: (url: string) => {
          return !startsWith(url, 'data:application/pdf') // 过滤pdf数据
        }
      })
    ],
    beforeBreadcrumb(breadcrumb) {
      const { category, level } = breadcrumb
      // 过滤掉pdf数据
      if (category === 'xhr') {
        const url = breadcrumb.data?.url
        if (startsWith(url, 'data:application/pdf')) {
          breadcrumb.data!.url = 'data:application/pdf'
          return null
        }
      } else if (category === 'console') {
        // console.error时上报
        if (level !== 'error') {
          return null
        }
      }
      return breadcrumb
    },
    tracesSampleRate: 1.0,
    environment: getEnvironment()
  })
  Sentry.setTag('application', 'esign-ui')
  sentryEventTracking.init()
}

class SentryEventTracking {
  static ENTRY = 'ENTRY'
  dafTransaction?: Sentry.Transaction
  eContractingTransaction?: Sentry.Transaction
  init() {
    const businessType = getBusinessType()
    if (businessType === BusinessType.DAF) {
      eventBus.on(SENTRY_ENTRY, this.startDAFReading)
      eventBus.on(SENTRY_DAF_CLICK_AGREE, this.finashDAFReading) // 阅读

      eventBus.on(SENTRY_DAF_AFTER_CLICK_AGREE, this.startDAFSignature) // 签署
      eventBus.on(SIGNATURE_EVENT_KEY, this.finashDAFSignature)
    } else if (businessType === BusinessType.E_CONTRACTING) {
      eventBus.on(SIGNATURE_PAGE, this.startEContractingSignature) // 签署
      eventBus.on(SIGNATURE_EVENT_KEY, this.finishEContractingSignature)
    }
  }
  startTransaction = (name: string) => {
    const transaction = Sentry.startTransaction({ name, op: 'performance' })
    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction))
    return transaction
  }
  startDAFReading = () => {
    this.dafTransaction = this.startTransaction(SENTRY_DAF_READING_DOCUMENTS)
  }
  finashDAFReading = () => {
    this.dafTransaction?.finish()
  }
  startDAFSignature = () => {
    this.dafTransaction = this.startTransaction(SENTRY_DAF_SIGNATURE)
  }
  finashDAFSignature = () => {
    this.dafTransaction?.finish()
  }
  startEContractingSignature = () => {
    this.eContractingTransaction = this.startTransaction(SENTRY_E_CONTRACTING_SIGNATURE)
  }
  finishEContractingSignature = () => {
    this.eContractingTransaction?.finish()
  }
}

export const sentryEventTracking = new SentryEventTracking()
