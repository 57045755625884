import { BusinessType } from '@/config/common'
import { getBusinessType } from '@/utils/storage'
import { ENABLE_EVENT_TRACKING } from './config'

export const eventTrackingSwitch = (target: any, key: string, describer: any) => {
  const originMethod = describer.value
  describer.value = function (...args: any[]) {
    if (ENABLE_EVENT_TRACKING) {
      originMethod.apply(this, args)
    }
  }
  return describer
}

export const eventTrackingByBusiness = (businessType: BusinessType) => (target: any, key: string, describer: any) => {
  const originMethod = describer.value
  describer.value = function (...args: any[]) {
    const currentBusinessType = getBusinessType()
    if (currentBusinessType === businessType) {
      originMethod.apply(this, args)
    }
  }
  return describer
}
