import { eventBus } from '@/utils/subscribe'
import {
  SIGNATURE_EVENT_KEY,
  SIGNATURE_PAGE,
  SIGNATURE_SUCCESS_PAGE,
  SIGNATURE_FAILED_PAGE,
  SIGNATURE_SUCCESS_PAGE_BUTTON,
  CONTRACT_FILES_PAGE_CLICK_NAV
} from './config'

export interface ISignatureEvent {
  request_result: string
  fail_reason?: string
  failed_reason?: string
  isManual?: boolean
}

export interface IVoid {
  (): void
}

export interface ISignature {
  (event: ISignatureEvent): void
}
/**
 * 公共事件注册
 */

//订阅签署事件
export const subscribeSignature = (signature: ISignature) => {
  eventBus.on(SIGNATURE_EVENT_KEY, signature)
}

export const desubscribeSignature = (signature?: ISignature) => {
  eventBus.off(SIGNATURE_EVENT_KEY, signature)
}

// 订阅浏览手写签名页面
export const subscribeBrowserSignature = (handler: IVoid) => {
  eventBus.on(SIGNATURE_PAGE, handler)
}

export const desubscribeBrowserSignature = (handler: IVoid) => {
  eventBus.off(SIGNATURE_PAGE, handler)
}

//订阅浏览签署成功页
export const subscribeSignatureSuccessPage = (signature: IVoid) => {
  eventBus.on(SIGNATURE_SUCCESS_PAGE, signature)
}

//订阅浏览签署成功页点击按钮
export const subscribeSignatureSuccessPageButton = (signature: (buttonName?: string) => void) => {
  eventBus.on(SIGNATURE_SUCCESS_PAGE_BUTTON, signature)
}

//订阅浏览签署失败页
export const subscribeSignatureFailedPage = (signature: IVoid) => {
  eventBus.on(SIGNATURE_FAILED_PAGE, signature)
}

export const desubscribeSignatureSuccessPage = (signature: IVoid) => {
  eventBus.off(SIGNATURE_SUCCESS_PAGE, signature)
}

export const desubscribeSignatureSuccessPageButton = (signature: (buttonName?: string) => void) => {
  eventBus.off(SIGNATURE_SUCCESS_PAGE_BUTTON, signature)
}

export const desubscribeSignatureFailedPage = (signature: IVoid) => {
  eventBus.off(SIGNATURE_FAILED_PAGE, signature)
}

export const subscribeContractFilesPageClickNav = (clickNav: (contractName: string) => void) => {
  eventBus.on(CONTRACT_FILES_PAGE_CLICK_NAV, clickNav)
}

export const emitSignatureEvent = (reason?: string, isManual = false) => {
  const result = reason ? '失败' : '成功'
  const event: ISignatureEvent = { request_result: result }
  if (reason) {
    event.fail_reason = reason
  }
  if (isManual) {
    event.isManual = true
  }
  eventBus.emit(SIGNATURE_EVENT_KEY, event)
}

export const emitBrowserSignatureEvent = () => {
  eventBus.emit(SIGNATURE_PAGE)
}
