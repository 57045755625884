import filter from 'lodash-es/filter'
import {
  getAttachmentContent,
  getAttachmentDocList,
  signingContractBySMSCode,
  signingContractManual
} from '@/api/backend'
import { alertMsg, toastMsg } from 'dmt-shared-libs'
import get from 'lodash-es/get'
import upperCase from 'lodash-es/upperCase'
import noop from 'lodash-es/noop'
import { Router, useRouter } from 'vue-router'
import { getBusinessType, getSessionStorage } from '@/utils/storage'
import { BusinessType } from '@/config/common'
import { emitSignatureEvent } from '@/event-tracking/eventbus'
import { errorCodeMapping } from '@/config/errors'

export const getContractList = async () => {
  const contractSignatureId = getSessionStorage('contractSignatureId')
  const companyId = getSessionStorage('companyId')
  const docList = await getAttachmentDocList(contractSignatureId, companyId)
  const groupOfDefaultDoc = filter(docList, item => item.catalogName === 'default')[0]
  const docWithoutTerms = filter(groupOfDefaultDoc['documents'], item => upperCase(item.docFlag) !== 'TERM')
  return docWithoutTerms
}

// 基于term与非term分组
export const getContractListCatetory = async () => {
  const contractSignatureId = getSessionStorage('contractSignatureId')
  const companyId = getSessionStorage('companyId')
  const docList = await getAttachmentDocList(contractSignatureId, companyId)
  const groupOfDefaultDoc = filter(docList, item => item.catalogName === 'default')[0]
  const docWithoutTerms = filter(groupOfDefaultDoc['documents'], item => upperCase(item.docFlag) !== 'TERM')
  return {
    list: docWithoutTerms,
    term: filter(groupOfDefaultDoc['documents'], item => upperCase(item.docFlag) === 'TERM')[0]
  }
}

export const getTargetContract = async () => {
  const docWithoutTerms = await getContractList()
  return docWithoutTerms[0]
}

export const getContractContent = async (contractId: string) => {
  const contractSignatureId = getSessionStorage('contractSignatureId')
  const companyId = getSessionStorage('companyId')
  const content = await getAttachmentContent(contractId, contractSignatureId, companyId)
  return content.file
}

export const getSuccessHandler = (router: Router, link?: string) => {
  const businessType = getBusinessType()
  const dafHandler = () => {
    toastMsg({ message: '确认成功', duration: 500 })
    if (link) {
      window.setTimeout(() => (location.href = link!), 500) // DAF直接跳转，一定有link
    }
  }
  const cssHandler = () => {
    router.push(`/signing-success?link=${link}`) // CSS先跳转成功页，然后点击按钮时跳转
  }
  const eContractingHandler = () => {
    toastMsg({ message: '确认成功', duration: 500 })
    if (link) {
      window.setTimeout(() => (location.href = link!), 500) // E-Contracting是直接跳转
    } else {
      router.push(`/signing-success`) // 无link直接跳转签署成功页
    }
  }
  const dmHandler = () => {
    router.push(`/signing-success?seconds=5&link=${link}`) // CSS先跳转成功页，然后点击按钮时跳转
  }
  const clHandler = () => {
    dafHandler() // TODO cl处理暂时按daf
  }
  const handlers = {
    [BusinessType.DAF]: dafHandler,
    [BusinessType.CSS]: cssHandler,
    [BusinessType.E_CONTRACTING]: eContractingHandler,
    [BusinessType.DM]: dmHandler,
    [BusinessType.CREDIT_LIMIT]: clHandler
  }
  return handlers[businessType]
}

export const useSigningContract = (isManual = false) => {
  const router = useRouter()
  const signingContract = async (content: string, resetSMSCode: () => void = noop) => {
    const contractSignatureId = getSessionStorage('contractSignatureId')
    const customerId = getSessionStorage('customerId')
    try {
      const signingFunction = isManual ? signingContractManual : signingContractBySMSCode
      const { link } = await signingFunction(contractSignatureId, customerId, content)
      emitSignatureEvent(undefined, isManual)
      const successHandler = getSuccessHandler(router, link || '')
      successHandler()
    } catch (error) {
      const isConsumed = get(error, 'isConsumed')
      const reason = get(error, 'ERROR_MESSAGE')
      emitSignatureEvent(reason, isManual) // 上报埋点错误
      if (isConsumed) {
        return
      }
      const code = get(error, 'data.error.code')
      if (code === 'EVENT_CERTIFICATE_FAILED') {
        return alertMsg({
          message: errorCodeMapping.EVENT_CERTIFICATE_FAILED,
          confirmButtonText: '确定',
          className: 'authorized-alert'
        })
      }

      if (code === 'DEPOSIT_EVIDENCE_FAILED') {
        return alertMsg({
          message: errorCodeMapping.SAVE_EVIDENCE_FAILED,
          confirmButtonText: '返回',
          className: 'authorized-alert'
        })
      }

      const message = get(errorCodeMapping, code!)
      if (message) {
        toastMsg({ message, className: 'toast-without-wrap' })
        resetSMSCode()
      } else {
        // 过期已经全局拦截处理，不能跳转签名错误
        router.push('/signing-error')
      }
    }
  }
  return {
    signingContract
  }
}
