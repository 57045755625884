import { getTrackingValues } from '@/api/backend'
import { getSessionStorage } from '@/utils/storage'
import includes from 'lodash-es/includes'
import { RouteLocationNormalized } from 'vue-router'
import { creditLimitEventTracking } from './business/creditLimit'
import { cssEventTracking } from './business/css'
import { dafEventTracking } from './business/daf'
import { dmEventTracking } from './business/dm'
import { eContractingEventTracking } from './business/eContracting'
import { ENABLE_EVENT_TRACKING } from './config'
import { eventTracking } from './EventTracking'

export const setGlobalProperties = (trackingData: any) => {
  dafEventTracking.setGlobalProperties(trackingData)
  creditLimitEventTracking.setGlobalProperties(trackingData)
  cssEventTracking.setGlobalProperties(trackingData)
  dmEventTracking.setGlobalProperties(trackingData)
  eContractingEventTracking.setGlobalProperties(trackingData)
}

const ignoreRoutes = ['landing', 'entry']

let trackingData: any
const getTrackingData = async () => {
  if (trackingData) {
    return trackingData
  }

  try {
    const contractSignatureId = getSessionStorage('contractSignatureId')
    const customerId = getSessionStorage('customerId')
    trackingData = await getTrackingValues(contractSignatureId, customerId)
    eventTracking.setReportStatus(true)
    setGlobalProperties(trackingData.data) // 进入页面之前先设置全局属性
  } catch (error) {
    eventTracking.setReportStatus(false)
    console.warn('get global properties failed, disabled qt report')
  }
}

export const eventTrackingGuard = async (to: RouteLocationNormalized) => {
  const { name } = to
  const isIgnoreRoute = includes(ignoreRoutes, name)
  if (ENABLE_EVENT_TRACKING && !isIgnoreRoute) {
    // 埋点开关关闭或入口页面就不走guard了
    await getTrackingData()
  }
}
