import { BusinessType } from '@/config/common'
import { getSessionStorage } from '@/utils/storage'
import { eventTrackingByBusiness } from '../decorator'
import { ISignatureEvent, subscribeBrowserSignature, subscribeSignature } from '../eventbus'
import { eventTracking } from '../EventTracking'

const contractListPageCode = 'dm_contract_list_page'
const contractDetailPageCode = 'dm_contract_detail_page'
const contractSignaturePageCode = 'dm_contract_sign_page'

class DMEventTracking {
  startTime = 0
  @eventTrackingByBusiness(BusinessType.DM)
  setGlobalProperties(trackingData: ITrackingData) {
    const {
      id_card_number,
      phone_number, // 手机号
      apply_type, // 客户类型 个人/公司
      contract_type, // 合同类型 奔驰金融/奔驰租赁
      operation_type, // 操作方 固定为C端用户
      business_type, // 业务类型 固定为解抵押
      user_role // 用户角色 解抵押人
    } = trackingData
    eventTracking.setUserId(id_card_number)
    eventTracking.setGlobalProperties({
      phone_number,
      apply_type,
      contract_type,
      operation_type,
      business_type,
      user_role
    })
    eventTracking.setUserInfo(phone_number, undefined)
    this.subscribe()
  }
  browserContractListPage() {
    //DM_合同列表页
    eventTracking.browserPage(contractListPageCode)
  }

  contractListClickContract(contractName: string) {
    //DM_合同列表页_合同文件点击
    sessionStorage.setItem('DM_CONTRACT_NAME', contractName) // 进入详情页时记录合同名称，然后在浏览或手写签署时上报
    eventTracking.clickEvent('dm_list_file_clk', contractDetailPageCode, { contract_name: contractName })
  }

  contractListClickConfirm(contractName: string) {
    // DM_合同列表页_确认签署点击, 全同有多个时怎么传contract_name? 取第一个？
    eventTracking.clickEvent('dm_list_confirm_clk', contractListPageCode, { contract_name: contractName })
  }

  contractListClickElectronicSign(contractName: string) {
    //DM_合同列表页_电子签名点击, 全同有多个时怎么传contract_name? 取第一个？
    sessionStorage.setItem('DM_CONTRACT_NAME', contractName) // 跳转手签前记录合同名称，然后在浏览或手写签署时上报
    eventTracking.clickEvent('dm_list_sign_clk', contractListPageCode, { contract_name: contractName })
  }

  browserContractDetail() {
    this.startTime = new Date().getTime() // 进入详情页时记录时间
    const contractName = getSessionStorage('DM_CONTRACT_NAME')
    //	DM_合同详情页
    eventTracking.browserPage(contractDetailPageCode, { contract_name: contractName })
  }

  contractDetailClickConfirm() {
    const duration = new Date().getTime() - this.startTime
    const contractName = getSessionStorage('DM_CONTRACT_NAME')
    //	DM_合同详情页_阅读并确认点击
    eventTracking.clickEvent('dm_detail_confirm_clk', contractDetailPageCode, {
      action_duration: duration / 1000,
      contract_name: contractName
    })
  }

  browserSignaturePage() {
    //DM_电子签署页
    const contractName = getSessionStorage('DM_CONTRACT_NAME')
    eventTracking.browserPage(contractSignaturePageCode, { contract_name: contractName })
  }

  signature(event: ISignatureEvent) {
    //DM_电子签署页_签署结果
    delete event.isManual
    const contractName = getSessionStorage('DM_CONTRACT_NAME')
    eventTracking.otherEvent('dm_contract_sign_result', contractSignaturePageCode, {
      ...event,
      contract_name: contractName
    })
  }

  @eventTrackingByBusiness(BusinessType.DM)
  subscribe() {
    subscribeBrowserSignature(this.browserSignaturePage)
    subscribeSignature(this.signature)
  }
}

export const dmEventTracking = new DMEventTracking()
