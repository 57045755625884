import { getSessionStorage } from './storage'

export enum CompanyType {
  AFC = '1',
  LC = '2'
}

export const getCompany = () => getSessionStorage('companyId')

export const isAFC = () => {
  const company = getCompany()
  return String(company) === CompanyType.AFC
}
export const isLC = () => {
  const company = getCompany()
  return String(company) === CompanyType.LC
}
