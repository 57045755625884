const urllocation = window.location.origin
let authority = ''

const callbackuri = window.location.origin + '/entry'

if (urllocation.indexOf('10088') > -1) {
  if (process.env.NODE_ENV === 'dev') {
    authority = 'https://ekyc-dev.mercedes-benz-finance.com.cn/identity'
  } else if (process.env.NODE_ENV === 'uat') {
    authority = 'https://ekyc-uat.mercedes-benz-finance.com.cn/identity'
  } else if (process.env.NODE_ENV === 'int') {
    authority = 'https://ekyc-int.mercedes-benz-finance.com.cn/identity'
  } else if (process.env.NODE_ENV === 'local') {
    authority = 'http://easymock.daimler.com/identity'
  } else {
    authority = 'https://ekyc-dev.mercedes-benz-finance.com.cn/identity'
  }
} else {
  if (urllocation.indexOf('dev') > -1) {
    authority = 'https://ekyc-dev.mercedes-benz-finance.com.cn/identity'
  } else if (urllocation.indexOf('uat') > -1) {
    authority = 'https://ekyc-uat.mercedes-benz-finance.com.cn/identity'
  } else if (urllocation.indexOf('int') > -1) {
    authority = 'https://ekyc-int.mercedes-benz-finance.com.cn/identity'
  } else if (urllocation.indexOf('esign.mercedes-benz-finance.com.cn') > -1) {
    authority = 'https://ekyc.mercedes-benz-finance.com.cn/identity'
  } else {
    authority = 'https://ekyc-dev.mercedes-benz-finance.com.cn/identity'
  }
}
window._oidcSettings = {
  authority: authority,
  client_id: 'a37bded96bf00ab3',
  redirect_uri: callbackuri,
  response_type: 'code',
  scope: 'openid profile',
  post_logout_redirect_uri: window.location.origin + '/logout',
  silent_redirect_uri: window.location.origin + '/renew',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  silentRequestTimeout: 5000,
  accessTokenExpiringNotificationTime: 100,
  clockSkew: 60 * 30,
  monitorSession: false,
  logLevel: 4
}
